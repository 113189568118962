<template>
  <div class="choose_cell">
    <a-modal
      title="选择小区"
      :visible="visible"
      width="70%"
      style="top: 8px"
      :maskClosable="false"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <a-form-model :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="小区名称">
              <a-input v-model="name"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item>
              <a-button type="primary" @click="getDataFilter"> 查询</a-button>
              <a-button type="primary" @click="reset"> 重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <!-- <a-row v-if="tableData.length > 0">
        <a-col :md="8" v-for="item in tableData" :key="item.index">
          <a-card
            :title="`【${item.province}】${item.name}`"
            class="my_card"
            @dblclick="toSubmit(item)"
          >
            <p>{{ item.contacts }} {{ item.phone }}</p>
            <p>{{ item.province }}{{ item.city }}{{ item.area }}{{ item.address }}</p>
          </a-card>
        </a-col>
      </a-row>
      <div v-else>暂无数据</div> -->
      <a-table
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        :pagination="page"
        bordered
        @change="changeTable"
        :customRow="changeTableRow"
        :row-selection="rowSelection"
      >
        <span slot="province" slot-scope="text, row"> {{ row.province }}{{ row.city }}{{ row.area }} </span>
      </a-table>
      <!-- <a-table
      v-if="tableData.length > 0"
        :loading="tableLoading"
        :row-selection="rowSelection"
        :rowKey="(record) => record.id"
        :columns="columns"
        :data-source="tableData"
        bordered
        :pagination="page"
        @change="changeTable"
      >
      </a-table>
      <div v-else>暂无数据:customRow="changeTableRow"</div>  -->
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ChooseCellAll',
  data() {
    return {
      visible: false,
      name: '',
      nameForm: {},
      tableData: [],
      selectedRowKeys: [],
      selectedRows: [],
      confirmLoading: false,
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      columns: [
        {
          title: '所属地址',
          dataIndex: 'action',
          key: 'action',
          align: 'center',
          ellipsis: true,
          scopedSlots: { customRender: 'province' },
        },
        {
          title: '详细地址',
          dataIndex: 'address',
          key: 'address',
          align: 'center',
          ellipsis: true,
        },
        {
          title: '小区',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
          ellipsis: true,
        },
      ],
    }
  },
  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        },
      }
    },
  },

  created() {},

  methods: {
    getList(obj) {
      this.visible = true
      obj.pageSize = this.page.pageSize
      obj.pageNumber = this.page.current
      this.nameForm = obj
      this.axios.get('/api/firm/community/dealerListData', { params: obj }).then((res) => {
        if (res.body.records) {
          this.tableData = res.body.records
          this.page.total = res.body.total
        }
      })
    },

    getData(FormObj) {
      FormObj.pageSize =this.page.pageSize
      FormObj.pageNumber = this.page.current
      this.axios.get('/api/firm/community/dealerListData', { params: FormObj }).then((res) => {
        this.tableData = res.body.records
      })
    },
    // 确认按钮
    handleOk() {
      this.visible = false
      this.$emit('getData', this.selectedRows[0])
    },
    handleCancel() {
      this.visible = false
    },
    // 查询
    getDataFilter() {
      this.nameForm.name = this.name
      this.getData(this.nameForm)
    },
    // 重置
    reset() {
      this.name = ''
      this.nameForm.name = ''
      this.page.pageSize = 10
      this.page.current = 1
      this.getData(this.nameForm)
    },
    /**
     * 点击行选中
     */
    changeTableRow(record) {
      return {
        on: {
          click: (e) => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          },
        },
      }
    },

    changeTable(pagination) {
      this.page = pagination
      this.getData(this.nameForm)
    },
  },
}
</script>

<style lang='scss' scoped>
.my_card {
  width: 95%;
  min-height: 180px;
  max-height: 180px;
  margin-bottom: 10px;
}
.active {
  border: 1px solid red;
}
</style>