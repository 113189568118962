<template>
  <a-card :border="false">
    <div class="realtor">
      <a-form-model ref="searchData" :label-col="{ xs: 7 }" :wrapper-col="{ xs: 17 }">
        <a-row :gutter="8">
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="任务名称">
              <a-input v-model="searchData.name" placeholder="请输入任务名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item label="客户名称">
              <a-input v-model="searchData.cusName" placeholder="请输入客户名称" allowClear></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="8" :xl="6" :xxl="6" :sm="12">
            <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
              <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
              <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>

      <div class="table-operator">
        <a-button type="primary" v-if="addEnable" icon="plus" @click="toHandler('add', {})">新建</a-button>
      </div>

      <a-table
        :data-source="tableData"
        :loading="tableLoading"
        :rowKey="(record) => record.id"
        :pagination="page"
        bordered
        @change="changeTable"
      >
        <a-table-column title="序号" align="center">
          <template slot-scope="text, row, index">{{ index + 1 }}</template>
        </a-table-column>
        <a-table-column title="任务名称" data-index="name" align="center"></a-table-column>
        <a-table-column title="客户名称" data-index="cusName" align="center"></a-table-column>
        <a-table-column title="状态" data-index="taskType" align="center">
          <template slot-scope="text">
            <span v-if="text == 1">进行中</span>
            <span v-if="text == 2">已完成</span>
            <span v-if="text == 3">未完成</span>
            <span v-if="text == 4">已取消</span>
          </template>
        </a-table-column>
        <a-table-column title="销售" data-index="executorName" align="center"></a-table-column>
        <a-table-column title="执行时间" data-index="executionTime" align="center"></a-table-column>
        <a-table-column title="操作" align="center" :width="150">
          <template slot-scope="text, row">
            <a v-if="editEnabel" @click="toHandler('edit', row)">编辑</a>
            <a-divider type="vertical" />
            <a v-if="delEnabel" @click="toHandler('del', row)">删除</a>
            <a-divider type="vertical" />
            <a v-if="checkEnabel" @click="toHandler('check', row)">查看</a>
          </template>
        </a-table-column>
      </a-table>
    </div>
    <div class="footer-btns" v-if="cusName != undefined">
      <a-button @click="onCancel">取消</a-button>
      <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
    </div>
    <TaskEditModal ref="TaskEditModal" :cusName="cusName" :cusId="cusId" @reload="getList(1)"></TaskEditModal>
  </a-card>
</template>

<script>
import TaskEditModal from './TaskEditModal.vue'
import { delDealerCustomerTask } from '../api/DealerCustomerInfoApi'
import { checkPermission } from '@/utils/permissions'

export default {
  components: {
    TaskEditModal,
  },

  data() {
    return {
      visible: false,
      tableLoading: false,
      btnsLoading: false,
      tableData: [],
      handle: '',
      searchData: {},
      page: {
        pageNumber: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      selectedRows: [],
      addEnable: checkPermission('dealer:task:add'),
      editEnabel: checkPermission('dealer:task:edit'),
      delEnabel: checkPermission('dealer:task:del'),
      checkEnabel: checkPermission('dealer:task:check'),
    }
  },

  props: ['cusName', 'cusId'],

  created() {
    this.getList(0)
  },

  methods: {
    // 获取数据源
    getList(num) {
      this.tableLoading = true
      if (num === 1) {
        this.searchData = {}
      }
      this.page.productStatus = 3
      this.searchData.cusId = !this.cusId ? '' : this.cusId
      const postData = Object.assign({}, this.page, this.searchData)
      this.axios.get('/api/dealer/customer/dealerCustomerTask/list', { params: postData }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.body.records
          this.page.total = res.body.total
          this.tableLoading = false
        }
      })
    },
    // 关闭弹框
    onCancel() {
      this.$emit('onload')
      this.visible = false
    },

    changeTable(pagination) {
      this.page = pagination
      this.page.pageNumber = pagination.current
      this.getList(1)
    },

    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },

    // 获取政策列表
    onSubmit() {
      this.$emit('onload')
      this.$emit('get-product-list', this.selectedRows)
      this.visible = false
      this.selectedRowKeys = []
    },

    toHandler(name, row) {
      const _this = this

      if (name === 'add') {
        _this.$refs.TaskEditModal.setRowData({}, 'add')
        return
      }

      switch (name) {
        case 'edit':
          _this.$refs.TaskEditModal.setRowData(row, 'edit')
          break
        case 'del':
          _this.$confirm({
            title: '删除警告',
            content: '确认要删除此条记录吗?',
            okText: '确认',
            cancelText: '取消',
            async onOk() {
              const res = await delDealerCustomerTask(row.id)
              if (res.code === 200) {
                _this.$notification.success({ message: res.message })
                _this.getList(0)
              } else {
                _this.$notification.error({ message: res.message })
              }
            },
            onCancel() {},
          })
          break
        case 'check':
          _this.$refs.TaskEditModal.setRowData(row, 'check')
          break
      }
    },
  },
}
</script>

<style scoped>
.steps-action {
  margin-top: 20px;
  text-align: right;
}
.steps-content {
  margin-top: 60px;
}
.footer-btns {
  text-align: right;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-top: 20px;
}
</style>