<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-17 17:58:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-05-17 18:25:33
-->
<template>
  <div>
    <a-modal title="选择商品" v-model="visible" width="70%" :footer="null" :maskClosable="false">
      <div class="realtor">
        <a-table :data-source="list" :loading="tableLoading" :rowKey="record => record.id" pagination="false" bordered>
            <a-table-column title="商品名称" data-index="goodsName" ></a-table-column>
            <a-table-column title="标签图标" data-index="goodsPicture" align="center">
              <template slot-scope="text">
                <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="分类" data-index="categoryName" align="center"></a-table-column>
            <a-table-column title="规格" data-index="goodsStr" align="center"></a-table-column>
            <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
            <a-table-column title="建议零售价" data-index="price" align="center">
              <template slot-scope="text">{{text.toFixed(2)}}</template>
            </a-table-column>
            <a-table-column title="数量" data-index="number" align="" :width="120"></a-table-column>
            <a-table-column title="单位" data-index="proUnit" align="center" :width="150"></a-table-column>
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">关闭</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        tableLoading: false,
      }
    },

    props: {
      list: {
        type: Array,
        default: []
      }
    },

    created() {},

    methods: {
      // 打开弹框触发
      isShow() {
        this.visible = true
      },
      // 关闭弹框
      onCancel() {
        this.visible = false
      },

    }
  }
</script>

<style scoped>
  .steps-action {
    margin-top: 20px;
    text-align: right;
  }
  .steps-content {
    margin-top: 60px;
  }
  .footer-btns {
    text-align: right;
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
    margin-top: 20px;
  }
</style>