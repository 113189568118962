<template>
  <div>
    <a-modal title="选择商品" v-model="visible" width="70%" :footer="null" :maskClosable="false">
      <div class="realtor">
        <a-form-model ref="searchData" :label-col="{xs:7}" :wrapper-col="{xs:17}">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="商品名称">
                <a-input v-model="searchData.name" placeholder="请输入商品名称"></a-input>
              </a-form-model-item>
            </a-col>
<!--            <a-col :md="8" :xl="6" :xxl="6" :sm="12">-->
<!--              <a-form-model-item label="商品分类">-->
<!--                <a-input v-model="searchData.categoryName" placeholder="请输入商品分类"></a-input>-->
<!--              </a-form-model-item>-->
<!--            </a-col>-->
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                <a-button type="primary" @click="getList(0)" icon="search">查询</a-button>
                <a-button type="default" @click="getList(1)" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <a-table :data-source="tableData" :loading="tableLoading" :rowKey="record => record.id" :pagination="page" bordered @change="changeTable" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
            <a-table-column title="图片" data-index="image" align="center">
              <template slot-scope="text">
                <ZoomMedia style="height: 20px;" :value="text"></ZoomMedia>
              </template>
            </a-table-column>
            <a-table-column title="商品名称" data-index="name" ></a-table-column>
            <a-table-column title="规格" data-index="specStr" align="center"></a-table-column>
            <a-table-column title="品牌" data-index="brandName" align="center"></a-table-column>
            <a-table-column title="建议零售价" data-index="price" align="center">
              <template slot-scope="text">{{text.toFixed(2)}}</template>
            </a-table-column>
<!--            <a-table-column title="数量" data-index="number" align="" :width="120">-->
<!--              &lt;!&ndash; <template slot-scope="text, row">-->
<!--                <a-input-number v-model="row.number" :min="1"></a-input-number>-->
<!--              </template> &ndash;&gt;-->

<!--            </a-table-column>-->
        </a-table>
      </div>
      <div class="footer-btns">
        <a-button @click="onCancel">取消</a-button>
        <a-button type="primary" :loading="btnsLoading" @click="onSubmit">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        visible: false,
        tableLoading: false,
        btnsLoading: false,
        tableData: [],
        handle: '',
        searchData: {},
        page: {
          pageNumber: 1,
          pageSize: 10,
          total: 0,
          showSizeChanger: true,
          showTotal: () => `共${this.page.total}条`,
        },
        selectedRowKeys: [],
        selectedRows: [],
      }
    },

    created() {},

    methods: {
      // 打开弹框触发
      isShow() {
        this.visible = true
        this.getList(0)
      },
      // 获取数据源
      getList(num) {
        this.selectedRowKeys = []
        this.selectedRows = []
        this.tableLoading = true
        if(num === 1) {
          this.searchData = {}
          this.page.pageSize = 10
          this.page.current = 1
        }
        let obj={
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
        productStatus:3
      }
        // this.page.productStatus = 3
        this.axios.get('/api/dealer/customer/dealerProOrderGoods/list',{params: obj} ).then(res => {
          if(res.code === 200) {
            this.page.total = res.body.total
            let vo = res.body.records.map(e => {
              return {
                goodsName: e.name,
                goodsPicture: e.image,
                goodsId: e.id,
                goodsStr: e.specStr,
                categoryName: e.categoryName,  // 分类
                brandName: e.brandName,     // 品牌
                price: e.price,            // 建议零售价
                proUnit: e.proUnit,         // 单位
                number: 1,
                ...e
              }
            })
            this.tableData = vo
            this.tableLoading = false
          }
        })
      },
      // 关闭弹框
      onCancel() {
        this.visible = false
      },

      changeTable(pagination) {
        this.page = pagination
        this.getList(0)
      },

      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
      },

      // 获取政策列表
      onSubmit() {
        this.$emit('get-product-list', this.selectedRows)
        this.visible = false
        this.selectedRowKeys = []
      },
    }
  }
</script>

<style scoped>
  .steps-action {
    margin-top: 20px;
    text-align: right;
  }
  .steps-content {
    margin-top: 60px;
  }
  .footer-btns {
    text-align: right;
    border-top: 1px solid #e8e8e8;
    padding-top: 20px;
    margin-top: 20px;
  }
</style>