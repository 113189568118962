<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-05-07 17:26:25
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-20 21:42:42
-->
<template>
  <a-modal :title="handle === 'add' ? '新增' : '编辑'" style="top: 8px" :width="1000" v-model="visible" :footer="null" :maskClosable="false">
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row>
         <a-col :span="12">
          <a-form-model-item label="所属渠道" prop="followType">
            <a-radio-group v-model="rowData.followType" @change="onFollowTypeChange">
              <a-radio :value="1"> 客户 </a-radio>
              <a-radio :value="2"> 渠道 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label="followTypeName" :prop="cusName != undefined ? 'cusName' : 'cusId'">
            <a-input v-model="rowData.cusName" v-if="cusName != undefined" placeholder="请输入" disabled></a-input>
            <a-select placeholder="请选择"  v-model="rowData.cusId" v-else>
              <a-select-option v-for="(item, index) in dataList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="任务名称" prop="name">
            <a-input
              v-model="rowData.name"
              :disabled="handle === 'check' ? true : false"
              placeholder="请输入"
            ></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="所属门店" prop="storeId" :autoLink="false" ref="item">
            <a-select placeholder="请选择" v-model="rowData.storeId" :disabled="handle === 'check' ? true : false" @change="onStoreChange">
              <a-select-option v-for="(item, index) in followerList" :key="index" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>

      </a-row>
      <a-row>
        <!-- <a-col :span="12">
          <a-form-model-item label="创建人" prop="createUser">
            <a-input v-model="rowData.createUser" placeholder="创建人" disabled></a-input>
          </a-form-model-item>
        </a-col> -->
        <a-col :span="12">
          <a-form-model-item label="执行人" prop="executorId">
            <a-select placeholder="请选择" v-model="rowData.executorId" >
                <a-select-option :value="item.id" v-for="(item, index) in managerList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            <!-- <a-input v-model="rowData.executor" placeholder="请输入" disabled></a-input> -->
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="执行时间" prop="executionTime">
            <DatePicker
              :startTime.sync="rowData.executionTime"
              :disabled="handle === 'check' ? true : false"
            ></DatePicker>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="任务方式" prop="taskMode">
            <a-radio-group v-model="rowData.taskMode" :disabled="handle === 'check' ? true : false">
              <a-radio :value="item.id" v-for="(item, index) in orderTypeList" :key="index">{{item.title}} </a-radio>
            </a-radio-group>
<!--            <a-radio-group v-model="rowData.taskMode" :disabled="handle === 'check' ? true : false">-->
<!--              <a-radio :value="0"> 单次 </a-radio>-->
<!--              <a-radio :value="1"> 循环 </a-radio>-->
<!--            </a-radio-group>-->
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="任务置顶">
            <a-switch v-model="rowData.topTask" :disabled="handle === 'check' ? true : false" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="任务状态">
            <a-radio-group v-model="rowData.taskType" :disabled="handle === 'edit' ? false : true">
              <a-radio :value="1" default-checked> 进行中 </a-radio>
              <a-radio :value="2"> 已完成 </a-radio>
              <a-radio :value="3"> 未完成 </a-radio>
              <a-radio :value="4"> 已取消 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-textarea
              v-model="rowData.remark"
              :disabled="handle === 'check' ? true : false"
              placeholder="请输入"
              :auto-size="{ minRows: 3, maxRows: 21 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-if="handle != 'add' && (rowData.taskType == 3 || rowData.taskType == 4)">
        <a-col :span="24">
          <a-form-model-item label="原因备注" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
            <a-textarea
              v-model="rowData.reasonNote"
              :disabled="handle === 'edit' ? false : true"
              placeholder="请输入"
              :auto-size="{ minRows: 3, maxRows: 21 }"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="btns-footer">
      <a-button type="default" @click="onCancel()">取消</a-button>
      <a-button type="primary" @click="toSubmit()" v-if="handle !== 'check'">提交</a-button>
    </div>
  </a-modal>
</template>

<script>
import {
  addDealerCustomerTask,
  editDealerCustomerTask,
  selectByIdDealerCustomerTask,
} from '../api/DealerCustomerInfoApi'

export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入任务名称', trigger: 'blur' }],
        executorId: [{ required: true, message: '请选择执行人', trigger: 'change' }],
        targetAmount: [{ required: true, message: '请输入目标金额', trigger: 'blur' }],
        executionTime: [{ required: true, message: '请选择执行时间', trigger: 'change' }],
        createUser: [{ required: true, message: '请输入创建人', trigger: 'blur' }],
        cusName: [{ required: true, message: '请输入客户名称', trigger: 'blur' }],
        cusId: [{ required: true, message: '请选择客户名称', trigger: 'blur' }],
        followType: [{ required: true, message: '请选择所属渠道', trigger: 'change' }],
        taskMode: [{ required: true, message: '请选择任务方式', trigger: 'change' }],
      },
      managerList: [],
      dataList: [],
      followerList: [],
      orderTypeList: [],
      followTypeName: '客户名称'
    }
  },

  props: ['cusName', 'cusId'],

  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.rowData.executionTime = null
      this.rowData.taskType = 1

      //获取客户
      this.axios.get(`/api/dealer/customer/dealerCustomerInfo/list?pageSize=99999`).then((res) => {
        this.dataList = res.body.records
      })

      this.axios.get(`/api/base/store/dealerStore/storeList`).then((res) => {
        this.followerList = res.body
      })

      this.axios.get(`/api/base/store/dealerStore/storeList`).then((res) => {
        this.followerList = res.body
      })

      this.axios.get('/api/base/system/dictionary/selectByCode/' + '任务方式').then((res) => {
        // 获取字典订单类型
        this.orderTypeList = res.body
      })

      if (handle === 'add') {
        this.$set(this.rowData, 'topTask', true)
        this.$set(this.rowData, 'taskMode', 0)
        this.$set(this.rowData, 'followType', 1)
      } else if (handle == 'edit' || handle === 'check') {
        selectByIdDealerCustomerTask(row.id).then((res) => {
          this.getManager(res.body.storeId)
          this.rowData = res.body
          this.rowData.createUser = this.$store.getters.userInfo.name
          this.rowData.createUserId = this.$store.getters.userInfo.id
          this.rowData.executor = this.$store.getters.userInfo.name
          this.rowData.executorId = this.$store.getters.userInfo.id
        })
      }
      if (this.cusId != undefined) {
        this.rowData.cusId = this.cusId
      }
      if (this.cusName != undefined) {
        this.rowData.cusName = this.cusName
      }
      this.rowData.createUser = this.$store.getters.userInfo.name
      this.rowData.createUserId = this.$store.getters.userInfo.id
      // this.rowData.executor = this.$store.getters.userInfo.name
      // this.rowData.executorId = this.$store.getters.userInfo.id
    },

    getCustomerList() {
      //获取客户
      this.axios.get(`/api/dealer/customer/dealerCustomerInfo/list?pageSize=99999`).then((res) => {
        this.dataList = res.body.records
      })
    },

    getChannelList() {
      //获取渠道
      this.axios.get(`/api/dealer/source/customerSource/list?pageSize=99999`).then((res) => {
        this.dataList = res.body.records.map(e => {
          return {
            ...e,
            name: e.customerName
          }
        })
      })
    },

    onFollowTypeChange(val) {
      let value = val.target.value
      this.checkedValue = value
      this.dataList = []
      if(value == 1) {
        this.followTypeName = '客户名称'
        this.getCustomerList()
        this.rowData.cusId = ''
        this.rowData.cusName = ''
      }

      if(value == 2) {
        this.followTypeName = '渠道'
        this.getChannelList()
        this.rowData.cusId = ''
        this.rowData.cusName = ''
      }
    },

    // 获取员工
    getManager(id) {
      this.axios.get(`/api/base/achievements/dealerAchievements/manager/choose?storeId=${id}`).then((res) => {
        this.managerList = res.body
      })
    },

    // 根据门店id获取该门店下的员工
    onStoreChange(val) {
      this.$refs.item.onFieldChange()
      this.getManager(val)
    },

    /**
     * 表单提交
     */
    toSubmit() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }

        const res =
          this.handle === 'add' ? await addDealerCustomerTask(this.rowData) : await editDealerCustomerTask(this.rowData)

        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },

    // 关闭弹框
    onCancel() {
      this.visible = false
    },

    onDete(value) {
      this.$refs.tesxt.onFieldChange()
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.btns-footer {
  text-align: right;
  margin-top: 20px;
}
</style>
